@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/functions';

.TextInput {
    width: 100%;
    position: relative;

        &__input {
            border: 2px solid #E6E6E6;
            border-radius: 10px;
            //padding: 24px 64px 24px 28px;
            padding-left: functions.responsivePercentageOfReference(0.25);
            padding-right: functions.responsivePercentageOfReference(0.50);
            padding-top: functions.responsivePercentageOfReference(0.15);
            padding-bottom: functions.responsivePercentageOfReference(0.15);
            width: 100%;
            //height: 78px;
            //height: functions.responsivePercentageOfReference(0.01);
            height: fit-content;
            transition: border 300ms;
            @include font.BodyFont();
            @include font.Small();

            &__multiline {
                //height: 120px;
                height: functions.responsivePercentageOfReference(1);
            }

            &__dashed {
                border-style: dashed;
            }

            &__solid {
                border-style: solid;
            }

            &:focus {
                border-color: var(--primary-color);
            }

            &:disabled {
                border-color: #E6E6E6;
            }

            &__success {
                border-color: #20A000;
                color: #20A000;
                @include font.SubheadingFont(false);
                @include font.Small();
            }

            &__error {
                border-color: #DB0000;
            }
        }

        &__icon {
            position: absolute;
            //top: 23px;
            //right: 26px;

            top: functions.responsivePercentageOfReference(0.10);
            right: functions.responsivePercentageOfReference(0.10);
            i {
                font-size: functions.responsivePercentageOfReference(0.40);
                //font-size: 32px;
                color: #A4A4A4;
            }
        }


    &__errorText {
        margin-top: 12px;
        color: #DB0000;
        @include font.BodyFont(false);
        @include font.Small();

        i {
            margin-right: 10px;
        }
    }

    &__suffixLabel {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: #A4A4A4;
        @include font.BodyFont(false);
        @include font.Small();
    }
}
