@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use "/src/stylesheets/functions";

$expandRelativeSize: common.$contentSizeRelative;


.ProductImages {
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
  user-select: none;
  @extend %flexCenter;
  transition: all 0.5s ease-in-out;

  &__expanded {
    background: black;
  }

  &__carousel {
    height: 100%;
    width: 100%;


    > div {
      height: 100%;
      width: 100%;

      > ul {
        pointer-events: none;
      }

      > div {
        > ul {
          height: 100vw;
          @include responsive.landscape {
            height: 100vh;
          }
        }
      }
    }

    &__expanded {
      > div {
        height: 100%;
        width: 100%;

        > div {
          > ul {
            height: 100vh;
            @include responsive.landscape {
              height: 100vh;
            }
          }
        }
      }
    }
  }

  &__images {
    @extend %flexCenter;
    height: 100%;
  }

  &__image {
    position: relative;
    object-fit: contain; //scale-down
    transition: all 0.5s ease-in-out;
    width: 100vw;
    height: 100%;
    pointer-events: none;
    @extend %backgroundContain;
  }

  &__navArea {
    &__spot {
      display: inline-block;
      transition: background-color 0.5s ease-in-out;
      background-color: rgba(0, 0, 0, 0.4);
      width: functions.responsivePercentageOfReference(0.175);
      height: functions.responsivePercentageOfReference(0.175);
      border-radius: 100%;
      border: 1px solid rgba(255, 255, 255, 0.60);
      border-width: functions.responsivePercentageOfReference(0.015);
      pointer-events: none;
      margin: functions.responsivePercentageOfReference(0.025);


      &__current {
        background-color: rgba(0, 0, 0, 1);
        pointer-events: none;
        border: 1px solid rgba(255, 255, 255, 1);
        border-width: functions.responsivePercentageOfReference(0.015);
      }
    }
  }

  &__expandIcon {
    background-color: rgba($color: colours.$black, $alpha: .5);
    border-radius: var(--input-radius);
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 0;
    margin-right: 2rem;
    width: functions.responsivePercentageOfReference($expandRelativeSize);
    height: functions.responsivePercentageOfReference($expandRelativeSize);
    color: white;
    transition: all 0.5s ease-in-out;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &__expanded {
      border: 1px solid rgba(255, 255, 255, 0.60);
    }

    &__pulse {
      pointer-events: none;
      background-color: rgba($color: colours.$grey, $alpha: .5);
      border-radius: var(--input-radius);
      border: 1px solid rgba($color: colours.$white, $alpha: 1);
      position: absolute;
      z-index: 1;
      bottom: 1rem;
      right: 0;
      margin-right: 2rem;
      width: functions.responsivePercentageOfReference($expandRelativeSize);
      height: functions.responsivePercentageOfReference($expandRelativeSize);
      color: white;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    > i {
      font-size: functions.responsivePercentageOfReference(0.25);
      padding: functions.responsivePercentageOfReference(0.1);
    }
    > path {
      fill: white !important;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    transform: scale(1.5);
    background-color: rgba($color: colours.$grey, $alpha: 0);
    border: 1px solid  rgba($color: colours.$white, $alpha: 0);
  }
}
