@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

$smallFontSize: 0.20;
$mediumFontSize: 0.25;
$largeFontSize: 0.30;
$heroFontSize: 0.35;

$smallLineHeightSize: 0.25;
$mediumLineHeightSize: 0.30;
$largeLineHeightSize: 0.35;
$heroLineHeightSize: 0.40;

@mixin CloudshelfFont() {
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin CloudshelfFontBold() {
    font-family: 'Be Vietnam Pro', sans-serif !important;
    font-weight: 700 !important;
    font-style: normal !important;
    overflow: hidden !important;
}

@mixin HeadingFont($withColour: true) {
    font-family: var(--headings-font);
    font-weight: var(--headings-font-weight);
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal;
    overflow: hidden;
}

@mixin SubheadingFont($withColour: true) {
    font-family: var(--subheadings-font);
    font-weight: var(--subheadings-font-weight);
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal;
    overflow: hidden;
}

@mixin BodyFont($withColour: true) {
    font-family: var(--body-font);
    font-weight: var(--body-font-weight);
    @if $withColour {
        color: var(--main-text-color);
    }
    font-style: normal;
    overflow: hidden;
}

@mixin Small {
    font-size: functions.responsivePercentageOfReference($smallFontSize);
    line-height: functions.responsivePercentageOfReference($smallLineHeightSize);
}

@mixin Medium {
    font-size: functions.responsivePercentageOfReference($mediumFontSize);
    line-height: functions.responsivePercentageOfReference($mediumLineHeightSize);
}

@mixin Large {
    font-size: functions.responsivePercentageOfReference($largeFontSize);
    line-height: functions.responsivePercentageOfReference($largeLineHeightSize);
}

@mixin Hero {
    font-size: functions.responsivePercentageOfReference($heroFontSize);
    line-height: functions.responsivePercentageOfReference($heroLineHeightSize);
}

.StyledText {
    display: block;
    line-height: 1.5;

    &__Center {
        text-align: center;
    }

    &__Cloudshelf {
        @include CloudshelfFont();
    }

    &__CloudshelfBold {
        @include CloudshelfFontBold();
    }

    &__Body {
        @include BodyFont();
    }

    &__Subheading {
        @include SubheadingFont();
    }

    &__Heading {
        @include HeadingFont();
    }

    &__Small {
        @include Small();
    }

    &__Medium {
        @include Medium();
    }

    &__Large {
        @include Large();
    }

    &__Hero {
        @include Hero();
    }
}
