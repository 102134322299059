@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/mobile';

.AttractSearch {
    height: 100%;
    width: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: mixins.z-index(attractSearch);
    transition: 0.5s all ease;

    &__show {
        background: rgba(0, 0, 0, 0.65);
        pointer-events: all;
    }

    &__button {
        @include font.SubheadingFont(false);
        color: black;
        background-color: white;

        &:disabled {
            filter: brightness(0.5);
        }
    }
}
