@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mobile';

.CategoryPane {
    //position: absolute;
    //top: 0;
    //left: 0;
    display: flex;
    width: 100vw;
    height: 100%;
    transform: translateX(100%) translateZ(0);
    background-color: var(--primary-color);
    z-index: z-index(DisplayOnlyCollectonPage);
    -webkit-transition: transform 0.5s 0s ease-in;
    -moz-transition: transform 0.5s 0s ease-in;
    -o-transition: transform 0.5s 0s ease-in;
    transition: transform 0.5s 0s ease-in;
    will-change: transform;

    &__Logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 95;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);

        & > img {
            margin-top: 45px;
            margin-bottom: 100px;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }

        &__small {
            max-height: 7vh;
            max-width: 20vw;
        }

        &__medium {
            max-height: 12vh;
            max-width: 30vw;
        }

        &__large {
            max-height: 15vh;
            max-width: 40vw;
        }
    }

    &__wrapper {
        //Intentionally empty so that react-transition-group works
    }
    
    &-enter {
        transform: translateX(100%)  translateZ(0);
    }

    &-enter-active {
        transform: translateX(0%) translateZ(0);
    }

    &-enter-done {
        transform: translateX(0%) translateZ(0);
    }

    &-exit {
        transform: translateX(0%) translateZ(0);
    }

    &-exit-active {
        transform: translateX(-100%) translateZ(0);
    }

    &-exit-done {
        transform: translateX(-100%) translateZ(0);
    }

    &__background {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__content {
        position: absolute;
        display: flex;
        //align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);

        &__title {
            @include font.HeadingFont();
            //@include font.Hero();
            color: white;
            text-align: center;
            overflow: visible;
            text-shadow: 0 0 50px rgba(0,0,0,0.3);
            width: 80vw;
            height: 40vh; //60
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-top:  calc(var(--vh, 1vh) * 22);

            & > div {
                height: 40vh;
                width: 100%;
            }
        }

        &__qRWrapper {
            position: absolute;
            bottom: 0;
            right: 0;
            background: white;
            padding: 8px;
            margin: 32px;
            border-radius: 10px;
            height: fit-content;
            width: fit-content;

            > img {
                width: 180px;
                height: 180px;
            }
        }
    }
}
