@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/common';

.PowerTilePage {
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        background-color: black;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }

    &__fadeIn {
        opacity: 1;
    }

    &__text {
        @include font.SubheadingFont();
        @include font.Medium();
        color: white;
        text-shadow: 0 0 4px #000000;
        text-align: center;
        margin-bottom: 3rem;

        &__icon {
            overflow: visible;
            font-size: 75px;
            line-height: 85px;

            @include responsive.tabletDesktop() {
                font-size: 100px;
                line-height: 115px;
            }
        }
    }

    &__qrContainer {
        background-color: white;
        border-radius: 10px;
        margin-top: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        -webkit-transition: margin-top 0.5s ease;
        -moz-transition: margin-top 0.5s ease;
        transition: margin-top 0.5s ease;

        &__bringTogether {
            margin-top: 0 !important;
        }

        &__qrHeader {
            @include font.BodyFont();
            @include font.Small();
            color: black;
            margin: 1rem 0 0 0;
            width: 100%;
            text-align: center;
            max-width: common.$qrCodeMaxSize;
        }

        &__wrapper {
            margin: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &__qrCode {
                width: common.$qrCodeSize;
                height: common.$qrCodeSize;
                min-height: common.$qrCodeMinSize;
                min-width: common.$qrCodeMinSize;
                max-height: common.$qrCodeMaxSize;
                max-width: common.$qrCodeMaxSize;
            }
        }

    }

    &__CSLogo {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        bottom: 0;
        background: white;
        border-radius: 20px 0 0 0;
    }

}
