@use "/src/stylesheets/functions";

.sampleText {
  z-index: 140;
  background-color: white;
  color: black;
  padding: 32px;
  display: inline-block;
  width: 100%;
  position: absolute;
  margin-top: functions.responsivePercentageOfReference(1);
  left: 0;
  top: 0;
  right: 0;
}

.space {
  padding-bottom: 1vh;
}