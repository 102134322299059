@use '/src/stylesheets/mobile';

.DisplayOnlyLoadingScreen {
    &__text {
        color: white !important;
    }

    &__info {
        margin-top: mobile.vhCalc(2);
    }

    &__loader {
        margin-top: mobile.vhCalc(5);
        height: auto !important;
    }
}