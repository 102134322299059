@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/colours';

.PcGenericWrapper {
    margin-top: functions.responsivePercentageOfReference(0.10);
}

.ImageUploadField {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__imageContainer {
        position: relative;
    }

    &__closeButton {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        color: white;
        border-radius: 100%;
        background-color: rgba(0,0,0,0.5);
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        line-height: 0;
    }

    &__image {
        max-height: 250px;
        max-width: 250px;
        margin-bottom: 16px;
    }

    &__qrCode {
        box-shadow: 0px 15px 15px -15px #000000
    }

    &__handoffContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__handoffText {
        margin-top: 26px;
    }

    &__cancelButton {
        margin-top: 32px;
    }

    &__qrContainer{
        position: relative;
    }

    &__loader {
        height: 100%;
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255,0.8);
    }

    &__button {
        width: 100%;
    }

    &__infoText {
        text-align: center;
        margin: 16px 0;
    }

    &__errorText {
        width: 100%;
        margin-top: 12px;
        color: #DB0000;
        @include font.BodyFont(false);
        @include font.Small();

        i {
            margin-right: 10px;
        }
    }
}

.SelectField {
    &__option {
        margin-top: functions.responsivePercentageOfReference(0.10);
        margin-right: functions.responsivePercentageOfReference(0.10);

        &__price {
            margin-left: functions.responsivePercentageOfReference(0.10);
            font-size: functions.responsivePercentageOfReference(0.15) !important;
            line-height: functions.responsivePercentageOfReference(0.20) !important;

            &--selected {
                color: colours.$primaryFontColor !important;
            }
        }
    }

    &__swatch {
        width: calc(var(--responsive-reference-point) * 0.25);
        height: calc(var(--responsive-reference-point) * 0.25);
        border-radius: 100%;
        margin-right: 8px;
        border: 1px solid #A4A4A4;
    }
}