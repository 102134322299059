@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.QRWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: calc(calc(var(--vh, 1vh) * 0.7));
    margin: calc(calc(var(--vh, 1vh) * 1.6) - 10px);
    border-radius: 10px;
    background: white;
    z-index: 95;

    @include responsive.landscape {
        padding: calc(calc(var(--vw, 1vw) * 0.7));
        margin: calc(calc(var(--vw, 1vw) * 6.7) - 10px) !important;
    }

    &__reducedMargin {
        margin: calc(calc(var(--vw, 1vw) * 2) - 10px) !important;
    }

    > img {
        width: calc(15vh * 0.8);
        height: calc(15vh * 0.8);
    }
}