.debugOverlays {
  z-index: 99999;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-height: 80%;
  font-size: 14px;
  color: white;
  text-shadow:
    0.05em 0 black,
    0 0.05em black,
    -0.05em 0 black,
    0 -0.05em black,
    -0.05em -0.05em black,
    -0.05em 0.05em black,
    0.05em -0.05em black,
    0.05em 0.05em black;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
}

.inside {
  margin: 10px;
  height: 80%;
  overflow: scroll;

  &__open {
    padding-bottom: 1em;
    max-height: 80%;
    overflow: scroll;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}

.darken {
  background-color: rgba(0, 0, 0, 1);
}
