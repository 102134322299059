@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';

.Player {
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 0;

    &__full {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
