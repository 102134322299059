@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';

.SessionModal {
    position: relative;
    @include common.space(calc(100% - #{common.spacing('xLarge') * 2}), calc(100% - #{common.spacing('xxxLarge') * 2}), 'height');
    @include common.space(common.spacing('xLarge') common.spacing('large'), common.spacing('xxxLarge') common.spacing('xxxLarge'), 'margin');

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1 {
        @include common.space(0 0 #{common.spacing('small')} 0, 0 0 #{common.spacing('xLarge')} 0,'margin');
        white-space: pre-line;
    }
}

.Session__continue {
    margin-top: 50px;
    color: colours.$white;
}
