@use '/src/stylesheets/mixins';
@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use 'sass:math';

.ProductCard {
    @extend %flexCenter;
    flex-flow: column;
    align-items: stretch;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #F5F5F5;
    border-radius: var(--tiles-radius);

    &__imageContainer {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
        position: relative;
        width: 100%;
        //aspect-ratio: 1/1;
    }

    &__image {
        @extend %backgroundCover;
        width: 100%;
        overflow: hidden;
        background-clip: border-box;
    }

    &__banner {
        border-top: 1px rgba(0, 0, 0, 0.15) solid;
        background-color: rgba(240, 240, 240, 0.7);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        @include font.SubheadingFont();
        @include font.Small();
        padding: common.spacing('semiSmall');
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        @include font.BodyFont();
    }

    &__text {
        width: 100%;
        height: 100%;
        text-align: center;
        color: var(--main-text-color);
        //margin: 0.5rem 0 0.5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__title {
        @include mixins.line-clamp($lines-to-show: 1);
        flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        padding: 0px 10px;
    }

    &:hover {
        filter: brightness(90%);
        position: relative;
    }

    &:active {
        transform: scale3d(0.95, 0.95, 1);
    }
    
    &__saleContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__originalPrice {
        text-decoration: line-through;
        color: colours.$saleOriginalColor;
        padding-right: 0.5rem;
    }

    &__newPrice {
        color: colours.$saleNewColor;
    }

}
