@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';

.NonResponsiveStyledText {
    &__Cloudshelf {
        @include font.CloudshelfFont();
    }

    &__CloudshelfBold {
        @include font.CloudshelfFontBold();
    }

    &__ExtraExtraSmall {
        font-size: 8px;
        line-height: 12px;

        @include responsive.tabletDesktop() {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &__ExtraSmall {
        font-size: 10px;
        line-height: 20px;

        @include responsive.tabletDesktop() {
            font-size: 20px;
            line-height: 34px;
        }
    }

    &__Small {
        font-size: 20px;
        line-height: 30px;

        @include responsive.tabletDesktop() {
            font-size: 30px;
            line-height: 44px;
        }
    }

    &__Medium {
        font-size: 25px;
        line-height: 36.5px;

        @include responsive.tabletDesktop() {
            font-size: 50px;
            line-height: 73px;
        }
    }

    &__Large {
        font-size: 70px;
        line-height: 80px;

        @include responsive.tabletDesktop() {
            font-size: 60px;
            line-height: 70px;
        }
    }
}