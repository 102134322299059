@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mixins';

.tapAreaInactive {
    pointer-events: none !important;
}

.tapArea {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: calc(var(--responsive-reference-point) * 0.2165);
    z-index: mixins.z-index(SalesPersonTapArea);
    pointer-events: all;

    &__visible {
        background-color: rgba(27, 39, 53, 0.5);
        border: 1px solid rgba(27, 39, 53, 0.7);
    }
}

.SelectionView {
    &__wrapper {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content {
        width: 100%;
        max-height: 80%;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 common.$panelBorderRadius common.$panelBorderRadius;

        @include responsive.tabletDesktop {
            width: 70vw;
            max-width: 43.75rem;
        }

        &__header {
            @include common.space(common.spacing('small'), common.spacing('medium'));
            display: flex;
        }

        &__body {
            display: block;
            flex: 1;
            overflow-y: scroll;
            @include common.space(common.spacing('small'), common.spacing('medium'));

            & > * {
                @include common.space(common.spacing('medium'), common.spacing('large'), margin-bottom);
            }

            & > *:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
}

.RadioContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default radio button */
    & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        transition: background-color 0.2s ease-in-out;

        /* Create the indicator (the dot/circle - hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover > input ~ .RadioContainer__checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    & > input:checked ~ .RadioContainer__checkmark {
        background-color: #2196F3;
    }

    /* Show the indicator (dot/circle) when checked */
    & > input:checked ~ .RadioContainer__checkmark:after {
        display: block;
    }

    & > .RadioContainer__checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
}
