@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/common';

.BrandingPage {
    background-color: white;
    opacity: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }

    &__fadeIn {
        opacity: 1;
    }

    &__text {
        @include font.SubheadingFont();
        @include font.Medium();
        text-align: center;
        margin-bottom: 3rem;
        background: #ff3968;
        background: -webkit-linear-gradient(to left, #ff3968 0%, #B70074 100%);
        background: -moz-linear-gradient(to left, #ff3968 0%, #B70074 100%);
        background: linear-gradient(to left, #ff3968 0%, #B70074 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__qrContainer {
        border-radius: 10px;
        margin-top: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #ff3968;
        background: -webkit-linear-gradient(to left, #ff3968 0%, #B70074 100%);
        background: -moz-linear-gradient(to left, #ff3968 0%, #B70074 100%);
        background: linear-gradient(to left, #ff3968 0%, #B70074 100%);

        -webkit-transition: margin-top 0.5s ease;
        -moz-transition: margin-top 0.5s ease;
        transition: margin-top 0.5s ease;

        &__bringTogether {
            margin-top: 0 !important;
        }

        &__qrHeader {
            @include font.BodyFont();
            @include font.Small();
            font-weight: bold !important;
            color: white;
            margin: 1rem 0 0 0;
            width: 100%;
            text-align: center;
            max-width: common.$qrCodeMaxSize;
        }

        &__wrapper {
            margin: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &__qrCode {
                background: white;
                padding: 16px;
                border-radius: 10px;
                width: common.$qrCodeSize;
                height: common.$qrCodeSize;
                min-height: common.$qrCodeMinSize;
                min-width: common.$qrCodeMinSize;
                max-height: common.$qrCodeMaxSize;
                max-width: common.$qrCodeMaxSize;
            }
        }

    }

    &__CSLogo {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 32px;
    }

}
