@use '/src/stylesheets/mixins';

.BackgroundSwitcher {
    overflow: hidden;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;

    &__Item {
        height: 100vh;
        width: 100vw;
        transition: opacity 1.2s;
        -webkit-transition: opacity 1.2s;
        -moz-transition: opacity 1.2s;
        -o-transition: opacity 1.2s;
        animation: slide 25s;

        &-appear {
            opacity: 0;
        }

        &-appear-active {
            opacity: 1;
        }

        &-appear-done {
            opacity: 1;
        }

        &-enter {
            opacity: 0;
        }

        &-enter-active {
            opacity: 1;
        }

        &-enter-done {
            opacity: 1;
        }

        &-exit {
            opacity: 1;
        }

        &-exit-active {
            opacity: 0;
        }

        &-exit-done {
            opacity: 0;
        }

        &__Image {
            position: absolute;
            top: 0;
            -webkit-background-size: cover !important;
            -moz-background-size: cover !important;
            -o-background-size: cover !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;

            &__Static {
                animation: none !important;
            }
        }
    }
}

@keyframes slide{
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -25vh, 0);
    }
}