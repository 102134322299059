@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';

.ProductAvailability {
  &__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__sectionStock {
    @include font.SubheadingFont();
    @include font.Small();
    @include common.space(common.spacing('small'), common.spacing('medium'), 'margin-top');
    margin-top: 0.1rem !important;
  }

  &__inStock {
    color: var(--purchase-text-color) !important;
  }

  &__unavailable {
    display: none;
  }

  &__limited {
    color: colours.$warning !important;
  }
}