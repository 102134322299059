@use 'src/stylesheets/functions';

.BouncingArrow {
  position: absolute;
  top: -2.5vw;
  justify-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  //Transitions
  -o-transition: transform 1.5s;
  -moz-transition: transform 1.5s;
  -webkit-transition: transform 1.5s;
  transition: transform 1.5s;

  //Transform Styles
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  animation: BouncingArrowBounce 1.5s infinite ease-in-out;
}

@keyframes BouncingArrowBounce {
    0% {
        -ms-transform: translateY(-12.5px);
        -o-transform: translateY(-12.5px);
        -moz-transform: translateY(-12.5px);
        -webkit-transform: translateY(-12.5px);
        transform: translateY(-12.5px);
    }
    50% {
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    100% {
        -ms-transform: translateY(-12.5px);
        -o-transform: translateY(-12.5px);
        -moz-transform: translateY(-12.5px);
        -webkit-transform: translateY(-12.5px);
        transform: translateY(-12.5px);
    }

}
