@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.Quantity {
    @extend %flexCenter;
    flex-flow: row;

    &__button {
        //padding: 0;
    }

    &__count {
        display: flex;
        @include common.space(0 common.spacing('xSmall'), 0 common.spacing('small'));
        text-align: center;
        &__smaller {
            @include font.BodyFont();
            @include font.Small();
        }
    }

    &__removeButton {
        background: transparent;
        border: none;
        padding: 0;
        @include common.space(common.spacing('medium'), common.spacing('large'), 'margin-left');

        &:active {
            font-weight: var(--body-font-weight) !important;
        }
    }
}
