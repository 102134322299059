@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.previewBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #222222;
  height: functions.responsivePercentageOfReference(0.50);
  z-index: 10000;
  padding: functions.responsivePercentageOfReference(0.05);

  &__previewText {
    color: #FFFFFF !important;
    font-size: functions.responsivePercentageOfReference(0.25) !important;
    line-height: functions.responsivePercentageOfReference(0.25) !important;
  }

  &__logoText {
    color: #FFFFFF !important;
    font-size: functions.responsivePercentageOfReference(0.25) !important;
    line-height: functions.responsivePercentageOfReference(0.25) !important;
  }

  &__logoImage {
    height: functions.responsivePercentageOfReference(0.25) !important;
  }
}

.previewLogoArea {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: functions.responsivePercentageOfReference(0.05);
}

