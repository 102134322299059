@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.advancedCompatibilityScreen {
    background: white;
    border: 1px solid #ccc;
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    padding: 1rem;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    color: #eee;
    border: 1px #1864ab solid;
    background-color: #1864ab;

    @include font.CloudshelfFontBold();
}

.compatibilityScreenDisplayModeButton {
    padding: 10px 10px;
    color: #eee;
    border: 1px #1864ab solid;
    background-color: #1864ab;

    @include font.CloudshelfFontBold();
}

.testResult {
    &_pending {
        color: white;
    }

    &_checking {
        color: white;
    }

    &_success {
        color: green;
    }

    &_failure {
        color: red;
    }
}