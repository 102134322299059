@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.PanelHeader {
    position: relative;
    width: 100%;
    @include common.space(common.spacing('medium') common.spacing('small'), common.spacing('large') common.spacing('medium'));

    &--withBorder {
        border-bottom: 1px solid #E8E8E8;
    }

    &__closeButton {
        position: absolute;
        height: 100%;
        width: min-content;
        right: 0;
        top: 0;
        padding: 0;

        .ButtonIcon__icon {
            height: 1.5rem;
            width: 1.5rem;
        }
        > span > img {
            fill: black;
            height: 100%;
        }
    }

    &__body {
        @extend %flexCenter;
        position: relative;
        width: 100%;
        height: 100%;

        &__leftAlign {
            justify-content: flex-start;
        }
    }


    &__title {
        @include mixins.line-clamp(2);
    }
}
