@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';

.FiltersView {
    width: 100vw;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    @include responsive.tabletDesktop {
        width: 65vw;
    }

    &__header {
        @include common.space(common.spacing('small'), common.spacing('medium'));
        display:flex;
    }

    &__list {
        @include common.space(common.spacing('small'), common.spacing('medium'));
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: none;

        & > * {
            @include common.space(common.spacing('small'), common.spacing('medium'), margin-bottom);
        }
    }
    &__footer {
        @include common.space(common.spacing('small'), common.spacing('medium'));
        box-shadow: common.$bottomPanelBoxShadow;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-content: space-around;
        justify-content: space-around;

        .Button {
            width: 50%;

            &:first-of-type {
                @include common.space(common.spacing('small'), common.spacing('medium'), margin-right);
            }
        }

        &__searchButton {
            color: colours.$white;
        }
    }
}
