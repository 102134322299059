@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.Chip {
    background-color: white;
    border: none;
    border-radius: functions.responsivePercentageOfReference(1);
    display: flex;
    flex-direction: row;
    gap: functions.responsivePercentageOfReference(0.1);
    align-items: center;
    cursor: pointer;
    padding: calc(var(--responsive-reference-point) * 0.1);

    &:active:not([disabled]) {
        transform: scale(0.9, 0.9);
    }

    
    &__RetailerStyled {
        background: var(--primary-color);
        color: white;
    }

    &__SubcategoryStyled {
        background: #D4D4D4;
        color: black;
    }

    &__InternalBadge {
        display: inline;
        background: black;
        color: white;
        border-radius: functions.responsivePercentageOfReference(1);
        margin-left:  functions.responsivePercentageOfReference(0.1);
        padding: 0px functions.responsivePercentageOfReference(0.1);
        min-height: functions.responsivePercentageOfReference(0.2);;
        //margin-right: functions.responsivePercentageOfReference(0.1);

        &__Loading {
            display: inline-block;
        }
    }

    &__Text {
        margin-left:  functions.responsivePercentageOfReference(0.1);
        //margin-right: functions.responsivePercentageOfReference(0.1);
        color: white !important;
        overflow: visible !important;

        &__Black {
            color: black !important;
            overflow: visible !important;
        }
    }

    &__Leading {
        display: flex;
        margin-left: functions.responsivePercentageOfReference(0.1);
        height: 150%;
    }

    &__Trailing {
        display: flex;
        margin-left: functions.responsivePercentageOfReference(-0.15);
        margin-right: functions.responsivePercentageOfReference(-0.1);
        height: 150%;
    }
}