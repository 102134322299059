@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/functions';

.CategoriesPage {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg,transparent,rgba(0,0,0,.9));
    cursor: grabbing;

    &__touchIndicatorWrapper {
        position: relative;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: block;
        width: 100%;
    }

    &__handIcon {
        @include mixins.z-index('favIcon');
        position: absolute;
        right: 0;
        top: 2.5rem;
        width: 20vw;
        height: 20vw;
        max-width: 12.5rem;
        max-height: 12.5rem;
        pointer-events: none;
        animation: pulse 1.5s ease infinite alternate;
        @keyframes pulse {
            from { transform: scale(0.6); }
            to { transform: scale(1.2); }
        }

        svg {
            width: 100%;
            height: 100%;
        }

        &__left {
            position: absolute;
            left: 0;
        }
    }

    &__drawer {
        //height: min-content;
        //max-height: 65%;
        z-index: 100;
        padding: 0px 2.2vw 2.2vw;
        background-color: #F5F5F5;
        box-shadow: 0 4px 40px rgba(0, 0, 0, 0.3);
        border-top-left-radius: common.$panelBorderRadius;
        border-top-right-radius: common.$panelBorderRadius;
        transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        transform: translateY(100%);

        &__header {
            //todo
            text-align: left;

            &__alignment {
                &__CENTER {
                    text-align: center;
                }

                &__RIGHT {
                    text-align: right;
                }
            }
        }

        &__content {
            transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            //todo
        }
    }
}