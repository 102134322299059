@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.white {
    color: white !important;
}

.deviceCode {
    @include font.CloudshelfFontBold();
    text-align: center;
    overflow: visible;

    font-size: 90px;
    @include responsive.mobileOnly {
        font-size: 80px;
    }
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
}

.title {
    display: flex;
    flex-direction: column;
    flex: 9;
    align-items: center;
    justify-content: center;
}