@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.StockLabel {

    &__inheritFont {
        font-size: inherit !important;
        line-height: inherit !important;
    }

    color: colours.$greyDark;

    position: relative;
    display: flex;
    align-items: center;

    > i {
        margin-right: 0.5rem;
    }

    &__inStock {
        & > * {
            color: var(--purchase-text-color) !important;
        }
    }

    &__limited {
        & > * {
            color: colours.$warning !important;
        }
    }

    &__onOrder {
        & > * {
            color: colours.$greyDark !important;
        }
    }

    &__tick {
        margin-right: common.spacing('xSmall');
    }
}
