@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.ProductPane {
    //position: absolute;
    //top: 0;
    //left: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    z-index: z-index(DisplayOnlyProductPane);
    -webkit-transition: transform 0.5s 0s ease-in;
    -moz-transition: transform 0.5s 0s ease-in;
    -o-transition: transform 0.5s 0s ease-in;
    transition: transform 0.5s 0s ease-in;
    will-change: transform;

    &-enter {
        transform: translateX(100%) scale(0.9) translateZ(0);
    }

    &-enter-active {
        transform: translateX(0%) scale(0.9) translateZ(0);
    }

    &-enter-done {
        transform: translateX(0%) scale(1) translateZ(0);
    }

    &-exit {
        transform: translateX(0%) scale(0.9) translateZ(0);
    }

    &-exit-active {
        transform: translateX(-100%) scale(0.9) translateZ(0);
    }

    &-exit-done {
        transform: translateX(-100%) scale(0.9) translateZ(0);
    }

    &__wrapper {
        display: flex;
        //flex-direction: column;
        flex-direction: column-reverse;
        height: inherit;

        @include responsive.landscape {
            flex-direction: row-reverse;
        }

        &__images {
            flex: 1;
            width: 100vw;
            //min-height: calc(100vh - 15vh);
            background: white;

            @include responsive.landscape {
                width: var(--display-only-landscape-images-width);
                height: 100% !important;
            }
        }

        &__productInfo {
            min-height: calc(var(--vh, 1vh) * 15);
            height: calc(var(--vh, 1vh) * 15);
            display: flex;
            flex-direction: row;
            width: 100vw;
            background: white;
            border-top: 1px solid #A4A4A4;
            //border-bottom: 1px solid #A4A4A4;

            @include responsive.landscape {
                height: calc(var(--vh, 1vh) * 100);
                width: var(--display-only-landscape-info-pane-width);
                border-top: 0;
                border-left: 1px solid #A4A4A4;
            }

            &__content {
                max-height: 100%;
                width: calc(100% - calc(var(--vh, 1vh) * 15));
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: calc(var(--vh, 1vh) * 3.4);
                justify-content: center;


                &__autoSizeArea {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex: 1;
                    max-width: 100%;
                    max-height: 100%;
                    justify-content: center;

                    @include responsive.landscape {
                        max-height: 50vh;
                        justify-content: unset;
                    }

                    &__force-size-for-autosizer {
                        width: 100%;
                        min-height: 33%;
                        max-height: 33%;
                        background: purple;
                    }
                }
                
                @include responsive.landscape {
                    justify-content: unset;
                }

                &__title {
                    overflow: visible;
                }

                p:first-child {
                    line-height: inherit;
                }

                @include responsive.landscape {
                    width: 100%;
                    max-height: 50vh !important;
                    padding: calc(var(--vw, 1vw) * 3.4);
                    padding-top: calc(var(--vw, 1vw) * 2.5);
                }


                &__previousPrice {
                    color: red;
                    text-decoration: line-through;
                    margin-right: 0.5em;

                    @include responsive.landscape {
                        display: block !important;
                    }
                }
            }
        }
    }
}
