@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/functions';
@use '/src/stylesheets/font';

$menuRelativeSize: common.$contentSizeRelative;

.Menu {
    //background:pink;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--responsive-reference-point);
    @include mixins.z-index(topMenu);
    display: flex;
    pointer-events: none;

    &__checkout {
        position: relative;

        & > div {
            background-color: var(--primary-color);
            display: flex;
            justify-content:center;
            align-items:center;
            height: 60% !important;
            aspect-ratio: 1;
            border-radius: 50%;
            position: absolute;
            top: functions.responsivePercentageOfReference(-1 * calc(calc(1 - $menuRelativeSize) /3));
            right: functions.responsivePercentageOfReference(-1 * calc(calc(1 - $menuRelativeSize) /3));
            @include font.BodyFont();
            font-size: functions.responsivePercentageOfReference(0.20);
            color: white;
            z-index: 0;
        }
    }

    &__fixed {
        height: 100%;
        width: 100%;
    }

    &__closeButton {
        all: unset;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content:center;
        align-items:center;
        height: 100%;
        //background: transparent;
        pointer-events: all;

        //semi transpatent black background
        background-color: rgba(0,0,0,0.5);

        & > * {
            height: 100%;
        }
    }

    &__roundButton {
        all: unset;
        position: relative;
        cursor: pointer;
        min-height: functions.responsivePercentageOfReference($menuRelativeSize);
        min-width: functions.responsivePercentageOfReference($menuRelativeSize);
        max-height: functions.responsivePercentageOfReference($menuRelativeSize);
        max-width: functions.responsivePercentageOfReference($menuRelativeSize);
        //height: 100%;
        display: flex;
        justify-content:center;
        align-items:center;
        //aspect-ratio: 1;
        border-radius: 50%;
        will-change: width;
        transition: 0.5s width ease-in-out, 0.5s border-radius ease-in-out;

        background-color: rgba(0, 0, 0, 0.5);

        &__retailerStyled {
            background-color: var(--primary-color);
        }

        &__expanded {
            flex: 1;
            border-radius: 5px;
        }

        & > * {
            height: 70%
        }

        &__smallerIcon {
            & > * {
                position: absolute;
                z-index: 1;
                height: 50%
            }
        }
    }

    &__content {
        //background-color: hotpink;
        position: relative;
        @extend %flexCenter;
        flex: 1;
        margin: auto 2.2vw;
        height: functions.responsivePercentageOfReference($menuRelativeSize);

        &__left {
            //background-color: deeppink;
            height: 100%;
            margin-right: 2.7vw;
            overflow: visible;
            pointer-events: all;
        }

        &__center {
            //background-color: lightpink;
            flex: 1;
            overflow: visible;
            pointer-events: all;
        }

        &__right {
            //background-color: deeppink;
            height: 100%;
            overflow: visible;
            display: flex;
            gap: 1.4vw;
            justify-content: flex-end;
            pointer-events: all;
        }
    }



    //&__backButton {
    //    &--background {
    //        background: rgba(0,0,0,0.1);
    //        border-radius: 100%;
    //    }
    //}
    //
    //&__checkoutIconContainer {
    //    overflow: visible;
    //    & > button {
    //        overflow: visible;
    //        background: $primaryColor;
    //        border-radius: 100%;
    //    }
    //}
    //
    //&__searchIconContainer {
    //    & > button {
    //        background: white;
    //        border-radius: 100%;
    //    }
    //}
    //&__search {
    //    & > span {
    //        margin-top: 5px;
    //        margin-left: 5px;
    //    }
    //}

}
