@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';

.BannerPlayer {
    width: 100%;
    height: 100%;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    will-change: opacity;

    &__in {
        -webkit-transition: opacity 1s ease-out 1.25s;
        -moz-transition: opacity 1s ease-out 1.25s;
        -o-transition: opacity 1s ease-out 1.25s;
        transition: opacity 1s ease-out 1.25s;
    }

    &__out {
        -webkit-transition: opacity 1s ease-out 1s;
        -moz-transition: opacity 1s ease-out 1s;
        -o-transition: opacity 1s ease-out 1s;
        transition: opacity 1s ease-out 1s;
    }

    &__full {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
