@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use 'sass:math';
@use '/src/stylesheets/functions';

$sliderColor: #e6e6e6;
$primaryColor: var(--primary-color);

.Slider {
    position: relative;
    overflow: visible;
    margin-left: functions.responsivePercentageOfReference(0.15);
    margin-right: functions.responsivePercentageOfReference(0.15);
    width: calc(100% - (var(--responsive-reference-point) * 0.3));
    height: functions.responsivePercentageOfReference(0.50);
}

.SliderRail__outer {
    position: absolute;
    width: 100%;
    height: functions.responsivePercentageOfReference(0.50);
    transform: translate(0%, -50%);
    cursor: pointer;
};

.SliderRail__inner {
    position: absolute;
    width: 100%;
    height: 2px;
    transform: translate(0%, -50%);
    pointer-events: none;
    background-color: $sliderColor;
};

.SliderTrack {
    position: absolute;
    transform: translate(0%, -50%);
    height: 2px;
    z-index: 1;
    background-color: $primaryColor;
    cursor: pointer;

    &--disabled {
        background-color: $sliderColor;
    }
}

.SliderHandle {
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: functions.responsivePercentageOfReference(0.3);
    height: functions.responsivePercentageOfReference(0.3);
    cursor: pointer;



    &--active {
        width: functions.responsivePercentageOfReference(0.35);
        height: functions.responsivePercentageOfReference(0.35);
    }

    > circle {
        fill: $primaryColor;
        stroke: $primaryColor;
    }
}

.SliderTick {
    &__label {
        position: absolute;
        text-align: center;
        @include font.BodyFont();
        @include font.Small();
       margin-top: functions.responsivePercentageOfReference(0.15);
    }

    &__tick {
        position: absolute;
        top: -0.375rem;
        width: 2px;
        height: 0.75rem;
        background-color: $primaryColor;
    }
}
