@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';

$paddingMobile: 1rem;
$paddingTabletDesktop: 2rem;

$horizontalPaddingMobile: common.spacing('xSmall') * 2 + common.$phoneIconWidth;
$horizontalPaddingTablet: common.spacing('small') * 2 + common.$phoneIconWidthTablet;

.PurchaseView {
    width: 100%;
    height: mobile.vhCalc(85);
    background-color: colours.$white;
    display: flex;
    flex-direction: column;

    &__body {
        @include common.space($paddingMobile 0, $paddingTabletDesktop 0, 'padding');
        position: relative;
        height: 100%;
        justify-items: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__totalPrice {
        color: black !important;
        @include common.space($paddingMobile, $paddingTabletDesktop, 'margin-bottom');
    }

    &__helpText {
        @include common.space(0 $horizontalPaddingMobile, 0 $horizontalPaddingTablet, 'padding');
        @include common.space($paddingMobile, $paddingTabletDesktop, 'margin-bottom');
        display: block;
        color: colours.$primaryLight;
        text-align: center;
    }

    &__checkoutText {
        //@include font.CloudshelfFont();
        //@include font.Sizes25();
        display: block;
        color: colours.$saleOriginalColor;
        text-align: center;
    }

    &__phone {
        top: 0;
        @include common.space(common.spacing('xLarge'), common.spacing('xxLarge'), 'margin-right');
    }

    &__paymentIcons {
        max-width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 24px;
        flex-wrap: wrap;
        gap: 22px;
    }

    &__branding {
        max-width: 10rem;
        margin-top: 7%;
        filter: invert(80%) sepia(12%) saturate(17%) hue-rotate(329deg) brightness(80%) contrast(87%);

        @include responsive.tabletDesktop() {
            max-width: 50rem;
        }
    }
}
