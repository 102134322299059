@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';

.Loader {
    @extend %flexCenter;
    @include mixins.z-index(loader);
    //width: 100%;
    //height: 100vh;

    &__text {
        @include font.CloudshelfFontBold();
        color: white;
    }

    &--light {
        .Loader__block {
            background-color: colours.$white;
        }
    }

    &--dark {
        .Loader__block {
            background-color: colours.$black;
        }
    }

    &__block {
        width: 0.3rem;
        height: 2.5rem;
        border-radius: 0.15rem;
        margin: 0.15rem;
        animation-fill-mode: both;
        display: inline-block;
    }
    
    &__block:nth-child(1) {
        -webkit-animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    
    &__block:nth-child(2) {
        -webkit-animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    
    &__block:nth-child(3) {
        -webkit-animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    
    &__block:nth-child(4) {
        -webkit-animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    
    &__block:nth-child(5) {
        -webkit-animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
        animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
}

@-webkit-keyframes line-scale {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}
@keyframes line-scale {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}