@use '/src/stylesheets/common';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.NameFilterInput {
    height: 100%;

    &__container {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: common.$roundedButtonBorderRadius;
        border-color: colours.$greyInput;
        background-color: colours.$greyInput;
    }


    &__input {
        @include font.BodyFont();
        font-size: functions.responsivePercentageOfReference(0.25);
        border: none;
        background: transparent;
        color: colours.$black;
        padding: functions.responsivePercentageOfReference(0.15);
        width: 100%;
    }


    &__icon {
        height: 100%;
        display: flex;
        justify-content:center;
        align-items:center;
        aspect-ratio: 1;
        & > * {
            height: 70%
        }

        & > img {
            filter: invert(1);
        }
    }
}
