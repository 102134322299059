.HandOffLoadingScreen {
    &__text {
        color: white !important;
    }

    &__info {
        margin-top: 2vh;
    }

    &__loader {
        margin-top: 5vh;
        height: auto !important;
    }
}