@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/functions';

$primaryColor: var(--primary-color);

.ButtonSelectable {
    position: relative;

    margin-right: common.spacing('small');
    @extend %flexCenter;

    &:active:not([disabled]) {
        transform: scale(0.9, 0.9);
    }

    &--disabled {
        > label {
            border-color: #A4A4A4;
            > span {
                color: #A4A4A4;
            }
        }

        color: #A4A4A4;
        border-color: #A4A4A4;
        pointer-events: none;
        opacity: 0.75;
        text-decoration: line-through;
    }

    &--selected {
        .ButtonSelectable__label {
            background-color: $primaryColor;
        }

        .ButtonSelectable__text {
            color: colours.$primaryFontColor;
        }
    }

    &__tick {
        margin-right: common.spacing('xSmall');
    }

    &__label {
        @extend %flexCenter;
        display: flex;
        flex-direction: row;
        border: 2px solid $primaryColor;
        border-radius: common.$roundedButtonBorderRadius;
        white-space: nowrap;
        cursor: pointer;
        align-items: center;
        padding: calc(var(--responsive-reference-point) * 0.1);

        &__noFrame {
            background: white;
            border: none !important;
        }

        & > span > svg {
            height: functions.responsivePercentageOfReference(0.25);
            width: functions.responsivePercentageOfReference(0.25);
        }
    }

    &__text {
        //height: calc(var(--responsive-reference-point) * 0.48);
        //&--auto {
        //    height: 100%;
        //    width: 100%;
        //}
        //
        //&--small {
        //    @include mixins.button-size-small();
        //}
        //
        //&--medium {
        //    @include mixins.button-size-medium();
        //}
        //
        //&--large {
        //    @include mixins.button-size-large();
        //}
    }


    &__input {
        position: absolute;
        visibility: hidden;
        height: 100%;
        width: 100%;
    }
}
