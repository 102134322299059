@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';

.DisplayOnlyGallery {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__images {
        display: flex;
        flex-direction: row;
        -webkit-transition: transform 0.5s 0s ease-in;
        -moz-transition: transform 0.5s 0s ease-in;
        -o-transition: transform 0.5s 0s ease-in;
        transition: transform 0.5s 0s ease-in;
        will-change: transform;
        height: 100%;

        &__imageWrapper {
            flex: 1;
            //width: 100vw;
            //height: 100%;
            //height: calc(100vh - 15vh);
            //min-height: calc(100vh - 15vh);

            @include responsive.landscape {
                width: var(--display-only-landscape-images-width);
                height: mobile.vhCalc(100);
            }

            > img {
                //width: 100vw;
                //height: calc(100vh - 15vh);
                width: 100vw;
                height: 100%;
                object-fit: cover;

                @include responsive.landscape {
                    width: var(--display-only-landscape-images-width);
                    //height: 100vh;
                    height: 100%;
                }
            }

            &__noBar {
                @include responsive.landscape {
                    width: 100vw;
                    height: mobile.vhCalc(100)
                }

                > img {
                    @include responsive.landscape {
                        width: 100vw;
                        height: 100%;
                    }
                }
            }
        }
    }
}
