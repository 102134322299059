.BrandLogoWrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(140deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 25%);
    width: 100%;
    height: 100%;

    &__BrandLogo {
        width: 30%;
        aspect-ratio: 10/3;
        z-index: 95;
        pointer-events: none;
        margin: 0 2.2vw;
        margin-top: calc((var(--responsive-reference-point) - (var(--responsive-reference-point) * 0.567)) / 2);

        & > img {
            display: block;
            object-fit: contain;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
            object-position: left top;
        }

        //We are awaiting size rules from tom, so for now, they are all going to be fixed to the same size

        &__sized {
            width: 100%;
            aspect-ratio: 10/3;
        }

    }
}