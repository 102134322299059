@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/functions';

.brandingBar {
  //flex: 0;
  display: none;
  height: functions.responsivePercentageOfReference(0.50);
  z-index: 10000;
  border-top: 1px solid #A4A4A4;

    &__in {
      display: flex;
    }

    &__out {
      display:none;
    }

    &__logoWrapper {
      width: 100%;
      background: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: functions.responsivePercentageOfReference(0.05);
      padding: functions.responsivePercentageOfReference(0.05);

      &__poweredBy {
        color: #000000 !important;
        cursor: pointer;
        text-align: right;
        @include font.CloudshelfFont();
        font-size: functions.responsivePercentageOfReference(0.17) !important;
        line-height: functions.responsivePercentageOfReference(0.17) !important;
      }

      &__logo {
        height: functions.responsivePercentageOfReference(0.25) !important;
      }

      &__logoText {
        color: #000000 !important;
        cursor: pointer;
        text-align: left;
        @include font.CloudshelfFontBold();
        font-size: functions.responsivePercentageOfReference(0.25) !important;
        line-height: functions.responsivePercentageOfReference(0.25) !important;
      }
    }
  }