// A map of breakpoints.
$breakpoints: (
  xs: 560px,
  sm: 834px,
  md: 1025px,
  lg: 1450px,
  xl: 1700px,
  xxl: 2150px,
);

$mobileBreakpoint: 767px;

@mixin isSafari {
    @media not all and (min-resolution:.001dpcm) {
        @content;
    }
}

@mixin mobileOnly {
  @media (max-width: $mobileBreakpoint - 1) {
    @content;
  }
}

@mixin tabletDesktop {
  @media (min-width: $mobileBreakpoint) {
    @content;
  }
}

@mixin fixed-full-screen() {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint, $landscape: false) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @if ($landscape) {
      @media (min-width: $breakpoint-value) and (orientation: landscape) {
        @content;
      }
    } @else {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }


  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint, $landscape: false) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @if ($landscape) {
      @media (max-width: ($breakpoint-value - 1)) and (orientation: landscape) {
        @content;
      }
    } @else {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    }


  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//
//  LANDSCAPE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include landscape() {}
@mixin landscape(){
  @media screen and (orientation: landscape){
    @content;
  }
}