@use '/src/stylesheets/font';
@use '/src/stylesheets/functions';

.ProductCustomiserSection {

    &__TotalCustomisationPrice {
        margin-top: 0.5em;
        display: flex;
        flex-direction: row-reverse;
    }

    &__title {
        &__price {
            display: inline;
            margin-left: functions.responsivePercentageOfReference(0.10);
            font-size: functions.responsivePercentageOfReference(0.15) !important;
            line-height: functions.responsivePercentageOfReference(0.20) !important;
        }
    }

    &__container {
        margin-bottom: calc(var(--responsive-reference-point) * 0.2);
        //> * {
        //    margin-top: calc(var(--responsive-reference-point) * 0.1);
        //}
    }

    //&__input {
    //    display: flex;
    //    flex-direction: row;
    //    align-items: flex-start;
    //    padding: 18px 24px 24px;
    //    width: 100%;
    //    height: functions.responsivePercentageOfReference(0.10);
    //    background: #FFFFFF;
    //    border: 2px solid #A4A4A4;
    //    border-radius: 10px;
    //
    //    @include font.BodyFont();
    //    @include font.Small();
    //
    //}
}
