@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';

.ProductCardOptions {
  display: flex;
  flex-direction: row;
  @include common.space(common.spacing('medium'), common.spacing('large'), 'padding-left');
  @include common.space(common.spacing('medium'), common.spacing('large'), 'padding-right');
  height: 100%;
  -webkit-box-shadow: 0px 4px 40px 5px rgba(0,0,0,0.3);
  box-shadow: 0px 4px 40px 5px rgba(0,0,0,0.3);

  &__buttonBuyNow,
  &__buttonOrderNow {
    margin: auto;
    color: colours.$white;
    background: var(--purchase-text-color);
    min-width: 200px;

    @include responsive.tabletDesktop {
      min-width: 300px;
    }
  }

  &__buttonAddToBasket {
    margin: auto;
    background-color: transparent;
    border: 2px solid var(--purchase-text-color);
    min-width: 200px;

    @include responsive.tabletDesktop {
      min-width: 300px;
    }

    > * {
      color: var(--purchase-text-color) !important;
    }
  }

  &__spacer {
    flex: 1 2 auto;
  }
}