@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';

.ElegantBannerPane {
    all: unset;
    display: flex;
    width: 100vw;
    height: 100%;
    background-color: var(--primary-color);
    z-index: mixins.z-index(DisplayOnlyBannerPage);
    -webkit-transition: opacity 1s ease-out 0.5s;
    -moz-transition: opacity 1s ease-out 0.5s;
    -o-transition: opacity 1s ease-out 0.5s;
    transition: opacity 1s ease-out 0.5s;
    will-change: opacity;
    flex-direction: column;

    &:active {
        //transform: scale(0.99);
        filter: brightness(90%);
    }

    &__wrapper {
        //Intentionally empty so that react-transition-group works
    }


    &-enter {
        -webkit-transition: opacity 1s ease-out 1s;
        -moz-transition:  opacity 1s ease-out 1s;
        -o-transition:  opacity 1s ease-out 1s;
        transition: opacity 1s ease-out 1s;
        opacity: 0;
    }

    &-enter-active {
        -webkit-transition: opacity 1s ease-out 1s;
        -moz-transition:  opacity 1s ease-out 1s;
        -o-transition:  opacity 1s ease-out 1s;
        transition: opacity 1s ease-out 1s;
        opacity: 0;
    }

    &-enter-done {
        -webkit-transition: opacity 1s ease-out 1s;
        -moz-transition:  opacity 1s ease-out 1s;
        -o-transition:  opacity 1s ease-out 1s;
        transition: opacity 1s ease-out 1s;
        opacity: 1;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
    }

    &-exit-done {
        opacity: 0;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        width: 100%;
        height: 100%;

        &__blurWrapper {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &__blur {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                filter: blur(8px);
                -webkit-filter: blur(8px);
                width: 110%;
                height: 110%;
                margin: -5%;
            }
        }
    }

    &__content {
        position: absolute;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);

        &__title {
            @include font.HeadingFont();

            color: white;
            text-align: center;
            overflow: visible;
            text-shadow: 0 0 50px rgba(0,0,0,0.3);
            width: 80vw;
            height: 40vh; //60
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            flex: 1;

            & > div {
                height: 40vh;
                width: 100%;
            }
        }

        &__interactiveSection {
            margin-bottom: 80px;
            align-items: end;
            display: block;

            &__wrapper {
                display: block;
            }

            &__button {
                all: unset;
                cursor: pointer;
                background: white;
                @include font.SubheadingFont();
                @include font.Small();
                border-radius: 100px;
                padding: 20px;
                position: relative;

                &:active {
                    transform: scale(0.95);
                }

                &:hover {
                    filter: brightness(90%);
                }
            }

            &__pulse {
                position: relative;
                top: calc(var(--responsive-reference-point) * 0.75);
                pointer-events: none;
                background-color: rgba($color: colours.$grey, $alpha: .9);
                border: 1px solid rgba($color: colours.$white, $alpha: .9);
                border-radius: 100px;
                width: 100%;
                color: white;
                animation: pulse-ring-2 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
                height: calc(var(--responsive-reference-point) * 0.8);
            }
        }


    }
}

@keyframes pulse-ring-2 {
    0% {
        transform: scale(.33);
    }
    80%, 100% {
        transform: scale(1.33);
        background-color: rgba($color: colours.$grey, $alpha: 0);
        border: 1px solid rgba($color: colours.$white, $alpha: 0)
    }
}


//Button
.ElegantBannerPaneButton {
    -webkit-transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    -moz-transition: transform 1s  ease-in 0s, opacity 1s ease-in 0s;
    -o-transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    will-change: transform, opacity;
    transform: translateY(20%) translateZ(0);
    opacity: 0;

    &-enter {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(20%) translateZ(0);
        opacity: 0;
    }

    &-enter-active {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-enter-done {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit {
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit-active {
        transform: translateY(20%) translateZ(0);
        opacity: 0;
    }

    &-exit-done {
        transform: translateY(20%) translateZ(0);
        opacity: 0;
    }
}



//Text
.ElegantBannerPaneText {
    -webkit-transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    -moz-transition: transform 1s  ease-in 0s, opacity 1s ease-in 0s;
    -o-transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    transition: transform 1s ease-in 0s, opacity 1s ease-in 0s;
    will-change: transform, opacity;
    transform: translateY(15%) translateZ(0);
    opacity: 0;

    &-enter {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(15%) translateZ(0);
        opacity: 0;
    }

    &-enter-active {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-enter-done {
        -webkit-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -moz-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        -o-transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transition: transform 1s ease-out 0.5s, opacity 1s ease-out 0.5s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit {
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit-active {
        transform: translateY(-15%) translateZ(0);
        opacity: 0;
    }

    &-exit-done {
        transform: translateY(-15%) translateZ(0);
        opacity: 0;
    }
}



//Image
.ElegantBannerPaneImage {
    -webkit-transition: transform 1s ease-in 0.5s, opacity 1s ease-in 0.5s;
    -moz-transition: transform 1s ease-in 0.5s, opacity 1s ease-in 0.5s;
    -o-transition: transform 1s ease-in 0.5s, opacity 1s ease-in 0.5s;
    transition: transform 1s ease-in 0.5s, opacity 1s ease-in 0.5s;
    will-change: transform, opacity;
    transform: translateY(10%) translateZ(0);
    opacity: 0;

    &-enter {
        -webkit-transition: transform 1s ease-out 0s, opacity 1s ease-out 0s;
        -moz-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        -o-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transform: translateY(10%) translateZ(0);
        opacity: 0;
    }

    &-enter-active {
        -webkit-transition: transform 1s ease-out 0s, opacity 1s ease-out 0s;
        -moz-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        -o-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-enter-done {
        -webkit-transition: transform 1s ease-out 0s, opacity 1s ease-out 0s;
        -moz-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        -o-transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transition: transform 1s ease-out 0s , opacity 1s ease-out 0s;
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit {
        transform: translateY(0%) translateZ(0);
        opacity: 1;
    }

    &-exit-active {
        transform: translateY(-10%) translateZ(0);
        opacity: 0;
    }

    &-exit-done {
        transform: translateY(-10%) translateZ(0);
        opacity: 0;
    }
}
