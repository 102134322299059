@use "/src/stylesheets/mobile";

.handoffText {
    color: white !important;
    text-align: center;
}

.handoffIcon {
    margin-bottom: 28px;
}

.noticeScreen {
    position: fixed;
    z-index: 10384843; // A big value :)
    background-color: #454545;
    width: 100vw;
    height: mobile.vhCalc(100);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
