@use '/src/stylesheets/font';
@use '/src/stylesheets/common';

.CouponInput {
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        width: 100%;
        height: 100%;
        transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

        :first-child {
            margin-right: 16px;
        }

        button {
            padding-left: 52px;
            padding-right: 52px;
        }

        &__show {
            opacity: 1;
            transform: scale(1);
            // something
            @include common.space(common.$mediumButtonSizeMobile, common.$mediumButtonSize, 'height');
        }

        &__hide {
            opacity: 0;
            transform: scale(0);
            height: 0;
        }
    }
}
