@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';

.CheckoutTotalOverlay {
  @extend %flexCenter;
  @include mixins.z-index(SlidingModal);
  @include common.space(common.spacing('medium'), common.spacing('xLarge'));
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #E8E8E8;


  &__checkoutButton {
    color: colours.$white;
  }

  &__totalContainer {
    width: 100%;
    max-width: common.$modalMaximumWidthTabletDesktop;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__summary {
    width: 100%;
    max-width: common.$modalMaximumWidthTabletDesktop;
    @include common.space(common.spacing('medium'), common.spacing('xLarge'), 'margin-bottom');
  }


  &__totalPrice {
    flex-shrink: 0;
  }

  &__subTotalPrice {
    text-decoration: line-through;
  }

  &__discount {
    & > * {
      color: var(--purchase-text-color);
    }
  }

  &__totalLabel {
    & > * {
      color: #A4A4A4;
    }

  }
}
