@use '/src/stylesheets/responsive';

.ProductsLoader {
    &__container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__bar {
        z-index: 100000;
        background: transparent;
        width: 75vw;
        height: 2vh;
        outline: white solid 2px;
        overflow: hidden;

        &__fill {
            background: white;
            width: 0;
            height: 100%;
            transition: width .5s ease-in-out;
        }
    }

    &__info {
        color: white !important;
        margin-top: 2vh;
        max-width: 60vw;
        text-align: center;
    }
}