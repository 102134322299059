@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';

.ProductPrice {
  &__price {
    @include font.BodyFont();
    @include font.Small();
    margin: auto;
    display: flex;
    flex-direction: row;
  }

  &__originalPrice {
    text-decoration: line-through;
    color: colours.$saleOriginalColor;
    padding-right: 1rem;
  }

  &__newPrice {
    color: colours.$saleNewColor;
  }
}