@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';

.accordion {
    background-color: #fff;
    cursor: pointer;
    padding: 10px;
    padding-left: 0 !important;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: all 0.4s ease-in-out;


    &__panel {
        padding: 0;
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        &__open {
            max-height: min-content;
        }
    }
}