@use '/src/stylesheets/mixins';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.TimeBar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 40px;
    z-index: 1000000;

    @include responsive.landscape {
        width: var(--display-only-landscape-images-width);
    }

    &__forceFullWidth {
        width: 100vw !important;
    }

    &__markers {
        width: 100%;
        margin: 16px;
        display: grid;
        gap: 1em;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;

        &__marker {
            width: 100%;
            height: 8px;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.2);
            border: 1px solid rgba(255, 255, 255, 0.2);

            &__animate {
                border-radius: 3px;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
                border: 1px solid rgba(255, 255, 255, 0.35);

                //transition: width 1s;
                //width: 0;
                transform-origin: 0 50%;
                animation: progress2;
                animation-play-state: paused;
                animation-timing-function: linear;
                animation-duration: 0ms;
            }

            &__complete {
                border-radius: 3px;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
            }
        }
    }
}

@keyframes progress {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}


@keyframes progress2 {
    0% {
        transform: scaleX(0%);
    }
    100% {
        transform: scaleX(100%);
    }
}
