@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.Skeleton {
    width: 100%;
    height: 100%;
    background: linear-gradient(
                    120deg,
                    #e5e5e5 30%,
                    #ffffff 38%,
                    #f2f2f2 40%,
                    #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 1.25s infinite;

    &__text {
        min-height: 30px;
    }

    &__multiline {
        min-height: 90px;
    }

    &__rectangle {
        min-height: 100%;
    }

    &__padding {
        margin-bottom: 0.5rem;
    }
}


@keyframes load{
    100%{
        background-position: -100% 0;
    }
}