@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use 'sass:math';

$borderThickness: .125rem;
$borderCornerSize: math.div(common.$qrCodeSize, 10);

.PurchaseQrCode {
    position: relative;
    width: common.$qrCodeSize;
    height: common.$qrCodeSize;
    min-height: common.$qrCodeMinSize;
    min-width: common.$qrCodeMinSize;
    max-height: common.$qrCodeMaxSize;
    max-width: common.$qrCodeMaxSize;
    border: $borderThickness solid colours.$greyLight;
    padding: common.spacing('xSmall');

    &:before, &:after, &__container:before, &__container:after {
        position: absolute;
        display: block;
        content: "";
        width: $borderCornerSize;
        height: $borderCornerSize;
    }

    &:before {
        top: (-1 * $borderThickness);
        left: (-1 * $borderThickness);
        border-top: $borderThickness solid colours.$black;
        border-left: $borderThickness solid colours.$black;
    }

    &:after {
        top: (-1 * $borderThickness);
        right: (-1 * $borderThickness);
        border-top: $borderThickness solid colours.$black;
        border-right: $borderThickness solid colours.$black;
    }

    &__container {
        width: 100%;
        height: 100%;

        &:before {
            bottom: (-1 * $borderThickness);
            left: (-1 * $borderThickness);
            border-bottom: $borderThickness solid colours.$black;
            border-left: $borderThickness solid colours.$black;
        }

        &:after {
            bottom: (-1 * $borderThickness);
            right: (-1 * $borderThickness);
            border-bottom: $borderThickness solid colours.$black;
            border-right: $borderThickness solid colours.$black;
        }
    }

    &__countdown {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        margin-left: 15vw;
        margin-right: 15vw;
        text-align: center;
    }
}

/* Lottie seems to ignore display properties unless you do this... */
.lf-player-container {
    display: flex;
    height: 100%;
}
