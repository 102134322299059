@use '/src/stylesheets/responsive';

.fill {
    width: 100%;
    height: 100%;
}

.allowOverflow {
    overflow: visible !important;
}

.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include responsive.landscape {
        -webkit-line-clamp: 3;
    }
}
