@use '/src/stylesheets/responsive';

.white {
    color: white !important;
}

.error_retailer {
    padding-bottom: 40px;
}

.error_title {
    display: flex;
    flex-direction: column;
    flex: 9;
    align-items: center;
    justify-content: center;
    color: white !important;
}