@use '/src/stylesheets/font';
@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

$cartRelativeSize: common.$contentSizeRelative;

hr {
  height: 1px;
  background-color: #E8E8E8;
  border: none;
  @include common.space(common.spacing('medium'), common.spacing('medium'), 'margin-top');
  @include common.space(common.spacing('medium'), common.spacing('medium'), 'margin-bottom');
}

:root {
  --product-view-content-margin: #{common.spacing('medium')};
  --product-view-content-margin-negative: -#{common.spacing('medium')};
  --product-view-content-margin-neative-double: -#{common.spacing('xxLarge')};

  @include responsive.tabletDesktop {
    --product-view-content-margin: #{common.spacing('large')};
    --product-view-content-margin-negative: -#{common.spacing('large')};
    --product-view-content-margin-neative-double: -#{common.spacing('xxxxLarge')};
  }
}

.productDisplayView {
  width: 100vw;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: black;
  overflow: scroll;

  &__toasterContainer {
    > div {
      > div {
        max-width: 100%;
      }
    }
  }

  &__toast {
    display: flex;
    align-items: center;
    color: colours.$white;
    width: 100%;

    > * {
      color: colours.$white;
    }

    > i {
      margin-right: 1rem;
    }
  }

  &__vertical__noScroll {
    overflow: hidden;

    @include responsive.landscape {
      overflow: auto;
    }
  }

  @include responsive.landscape {
    flex-direction: row;
  }

  &__zIndex {
    @include mixins.z-index('productView');
  }

  &__images {
    height: 100vw; // 50vh We use 100vw so that the image is square, 50vh may give a better experience for the user.
    width: 100vw;
    min-height: 100vw;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;

    @include responsive.landscape {
      flex-direction: row;
      height: 100%;
      width: 50vw;
      min-height: 100%;
      min-width: 50vw;
    }

    &__fullscreen {
        height: 100%;
        width: 100vw;
    }

    &__empty {
      height: mobile.vhCalc(10);
      background-color: black;

      &__text {
        text-align: center;
        color: white !important;
        line-height: mobile.vhCalc(10) !important;

        @include responsive.landscape {
          line-height: mobile.vhCalc(100) !important;
        }
      }
    }
  }

  &__content {
    padding: var(--product-view-content-margin);
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;

    @include responsive.landscape {
      overflow: scroll;
      height: 100%;
    }

    &__hidden {
      margin-bottom: var(--product-view-content-margin-neative-double);
      height: 0;

      @include responsive.landscape {
        margin-bottom:0;
        padding: 0;
        width: 0;
        opacity: 0;
        height: 100%;
      }
    }
  }

  &__cartOptions {
    position: absolute;
    bottom: 0;
    right: 0;
    //height: 120px;
    height: var(--responsive-reference-point);
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    transition: all 0.5s ease-in-out;

    @include responsive.landscape {
      width: 50%;
    }

    &__hidden {
      margin-bottom: calc((-1 * var(--responsive-reference-point)) + var(--product-view-content-margin-neative-double));
    }

    &__spacer {
      min-height: calc(var(--responsive-reference-point) + var(--product-view-content-margin));
    }
  }
}