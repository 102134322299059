/*
 * COLOURS
 */

$white:                     #ffffff;
$black:                     #000000;

$primary:                   $black !default;
$primaryLight:              lighten($primary, 15) !default;
$primaryDark:               darken($primary, 15) !default;

$secondary:                 #f1dac4 !default;
$secondaryLight:            lighten($secondary, 15) !default;
$secondaryDark:             darken($secondary, 15) !default;

$grey:                      #c1c1c1 !default;
$greyLight:                 lighten($grey, 20) !default;
$greyDark:                  darken($grey, 20) !default;

$greyInput:                 #f5f5f5;
$greyTitle:                 #5b5b5b;

$green:                     #20A000;

$blue:                      #0500FF;

$success:                   #28a745;
$warning:                   #FB9600;
$error:                     #dc3545;

$saleOriginalColor:        #A4A4A4;
$saleNewColor:              #DB0000;

$primaryFontColor:          $white;

$overlayColor:              rgba($color: $black, $alpha: 0.6);
