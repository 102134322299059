@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';

.BasketList {
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  @include common.space(common.spacing('small'), common.spacing('medium'));
}

.salesPerson {
  text-align: center;
  @include common.space(common.spacing('small'), common.spacing('medium'));
}