@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/functions';

.ProductVariantOptions {

    &__section {
        display: flex;
        flex-direction: column;
        margin: common.spacing('small') 0;
    }

    &__title {
        width: 100%;
        @include font.SubheadingFont();
        @include font.Small();
    }

    &__options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__option {
        margin-top: functions.responsivePercentageOfReference(0.10);
        margin-right: functions.responsivePercentageOfReference(0.10);
        //margin-top: common.spacing('small');
        //margin-right: common.spacing('small');
    }
}
