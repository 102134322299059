@use '/src/stylesheets/common';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

.RangeFilterInput {
    &__label {
        @include common.space(common.spacing('small'), common.spacing('large'), margin-bottom);
        @include font.BodyFont();
        @include font.Small();
    }
}
