.SetupWrapper__Text {
    color: white !important;
}

.SetupWrapper__Info {
    margin-top: 2vh;
}

.SetupWrapper__Loader {
    margin-top: 5vh;
    height: auto !important;
}