@use '/src/stylesheets/common';
@use '/src/stylesheets/mixins';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

.CategoryProducts {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__ContentWrapper {
    padding: 0px 2.2vw;
    transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    margin-top: functions.responsivePercentageOfReference(1);
    transform: translateY(100%);
    display: flex;
    flex-direction: column;

    &__FilterContainer {
      display: flex;
      flex-direction: column;
      gap: functions.responsivePercentageOfReference(0.075);
      margin-bottom: functions.responsivePercentageOfReference(0.075);

      &__Selected {
        display: flex;
        gap: 1.04vw;
        overflow-x: scroll;
        flex-wrap: wrap;

        &__Chip {
          height: functions.responsivePercentageOfReference(0.48);

          &__Chevron {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 1;

            & > * {
              height: 70%
            }
          }
        }
      }

      &__Options {
        display: flex;
        gap: 1.04vw;
        overflow-x: scroll;

        &__Chip {
          height: functions.responsivePercentageOfReference(0.48);
        }
      }
    }

    &__GridContainer {
      //Nothing Yet
    }
  }
}