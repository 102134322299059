@use '/src/stylesheets/base';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/mobile';
.CheckoutView {
  width: 100vw;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  &__basketList {
    flex-grow: 1;
  }
}