@use '/src/stylesheets/responsive';
@use '/src/stylesheets/mobile';
@use '/src/stylesheets/functions';

:root {
    //Responsive Units
    --vh: 1vh;
    --responsive-reference-point: min(15vw, 7.9vh);
    --device-dpi: 96;

    //Colours
    --primary-color: #000000;
    --main-text-color: #000000;
    --purchase-text-color: #20A000;

    //Radius
    --input-radius: '7rem';
    --drawer-radius: '2rem';
    --tiles-radius: '0.85rem';
    --modal-radius: '0.85rem';

    //Font Families
    --headings-font: 'NunitoSans';
    --subheadings-font: 'NunitoSans';
    --body-font: 'NunitoSans';

    //Font Weights
    --headings-font-weight: '700';
    --subheadings-font-weight: '400';
    --body-font-weight: '300';

    //Display Only Landscape
    --display-only-landscape-images-width: 70vw;
    --display-only-landscape-info-pane-width: 30vw;

}

html {
    @include responsive.fixed-full-screen;
}

body {
    @include responsive.fixed-full-screen;
}

* {
    box-sizing: border-box;
}


::-webkit-scrollbar {
    display: none;
}

* {
    scrollbar-width: none;
    -webkit-tap-highlight-color: transparent;
}

*:not(input, textarea) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: mobile.vhCalc(100);
    display: flex;
    flex-direction: column-reverse;
}
