@use '/src/stylesheets/common';
@use '/src/stylesheets/colours';
@use '/src/stylesheets/font';
@use '/src/stylesheets/responsive';

$imageSizeMobile: 6.5rem;
$imageSizeTabletDesktop: 13rem;

.BasketListItem {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include common.space(common.spacing('small'), common.spacing('medium'), 'margin-bottom');
  @include common.space($imageSizeMobile, $imageSizeTabletDesktop, 'min-height');

  &:last-of-type {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &__image {
    margin-right: common.spacing('large');
    width: $imageSizeMobile;
    height: $imageSizeMobile;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    filter: drop-shadow(0px .125rem .25rem rgba(0, 0, 0, 0.25));
    background-color: white;
    border-radius: var(--tiles-radius);

    @include common.space(common.spacing('small'), common.spacing('medium'), 'margin-right');

    @include responsive.tabletDesktop() {
      width: $imageSizeTabletDesktop;
      height: $imageSizeTabletDesktop;
    }
  }

  &__name {
    @include font.SubheadingFont();
    @include font.Small();
    font-weight: var(--subheadings-font-weight) !important;
  }

  &__optionsListItem {
    @include font.BodyFont();
    @include font.Small();
    font-weight: var(--body-font-weight) !important;
  }

  &__quantity {
    justify-content: flex-start;
  }

  &__details {
    flex-grow: 1;
    height: 100%;
  }

  &__price {
    @include font.SubheadingFont();
    @include font.Small();
    font-weight: var(--subheadings-font-weight) !important;
    flex-shrink: 0;
  }
}
