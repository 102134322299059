@use './colours';
@use './responsive';

$contentSizeRelative: 0.567;

/**
 * RATIO
 */
$mobileRatio:               0.8;

/*
 * SPACING
 */

// 1rem = 16px (set on html, see base.scss)
$spaces: (
    xSmall:             0.5rem,         // 8px;
    semiSmall:      0.625rem,       // 10px;
    small:              1rem,           // 16px;
    medium:             1.5rem,         // 24px;
    large:              2rem,           // 32px;
    xLarge:             2.5rem,         // 40px;
    xxLarge:            3rem,           // 48px;
    xxxLarge:           3.5rem,         // 56px;
    xxxxLarge:           4rem,
);

@function spacing($key) {
    @return map-get($spaces, $key);
}

/*  
 *  How to use:
 *  @include space(0 space('small'), 0 space('large'), 'margin', 'lg')
 */

@mixin space($mobile, $desktop, $property: 'padding', $breakpoint: 'md') {
    #{$property}: $mobile;

    @if $desktop {
        @include responsive.tabletDesktop {
            #{$property}: $desktop;
        }
    }
}

/*
 * BORDER
 */

$borderRadius:              var(--tiles-radius); //0.85rem;
$commonBorder:              0.01rem solid colours.$greyLight;

/*
 * BUTTON
 */

$roundedButtonBorderRadius: var(--input-radius); //7rem;
$squaredButtonBorderRadius: 0.25rem;

$bottomPanelBoxShadow:      0 .25rem 2.5rem rgba(0, 0, 0, 0.3);
$buttonBoxShadow:           rgba(0, 0, 0, 0.2) 0px 0.15rem 0.3rem -0.075rem, rgba(0, 0, 0, 0.12) 0px 0.15rem 0.3rem -0.075rem;

$largeButtonSizeMobile:     4rem;
$largeButtonSize:           6.5rem;
$mediumButtonSizeMobile:    3.5rem;
$mediumButtonSize:          5rem;
$smallButtonSizeMobile:     2rem;
$smallButtonSize:           3rem;

/*
 * TOP MENU
 */

$topMenuHeightMobile:       $mediumButtonSizeMobile + (2 * spacing('small'));
$topMenuHeight:             ($mediumButtonSize) + (2 * spacing('medium'));
 
 
/*
 * PRODUCT VIEWER
 */

$productViewerFooterHeight: 7.5rem;

/*
 * MODAL
 */
$modalMaximumWidthTabletDesktop: 73rem;

/*
 * PANEL
 */
$panelHeaderHeight: 5rem;
$panelHeaderHeightMobile: $mobileRatio * $panelHeaderHeight;
$panelBorderRadius: var(--drawer-radius); //2rem;

/**
 * QR CODE
 */
$qrCodeSize: 33vw;
$qrCodeMinSize: 8rem;
$qrCodeMaxSize: 15rem;

/**
 * ICON
 */
$iconSizeMobile: 1.5rem;
$iconSize: 3rem;

/**
 * Phone Icon
 */
$phoneIconHeight: 6.5rem;
$phoneIconHeightTablet: 13rem;
$phoneIconWidth: 3rem;
$phoneIconWidthTablet: 6rem;

/**
 * GRID - Maximum size is 7 columns of 280px with 24px between each.
 */
$maxGridWidth: 131.5rem;

/*
 * Z-INDICES
 */
$z-index: (
    //DisplayOnly
    DisplayOnlyBannerPage: 160, //110
    DisplayOnlyCollectonPage: 160,
    DisplayOnlyProductPane: 150,

    //Interactive
        topMenu:                160,
        attractSearch: 159,
    SalesPersonTapArea:     1000,
    SlidingModal:           170,
    topMenuModal:           130,
    productView:            119,
    modalHeader:            110,
    modal:                  100,
    favIcon:                25,
    loader:                 20,
    infinite-scroll:        15,
    grid:                   10,
    grid-gradient:          5,
);

/*
 * FLEX
 */

/*  
 *  How to use:
 *  @extend %flexCenter;
 */

%flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*  
 *  How to use:
 *  @extend %flexCenterVertically;
 */

%flexCenterVertically {
    display: flex;
    align-items: center;
    height: 100%;
}

/*  
 *  How to use:
 *  @extend %flexCenterHorizontally;
 */

%flexCenterHorizontally {
    display: flex;
    justify-content: center;
}

/*
 * BACKGROUND
 */

/*  
 *  How to use:
 *  @extend %backgroundContain;
 */

%backgroundContain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/*  
 *  How to use:
 *  @extend %backgroundCover;
 */

%backgroundCover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/*  
 *  How to use:
 *  @extend %backgroundGradientDark;
 */

%backgroundGradientDark {
    background: colours.$black;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.8) 70%,
        rgba(0, 0, 0, 0.7) 75%,
        rgba(0, 0, 0, 0.6) 80%,
        rgba(0, 0, 0, 0.5) 85%,
        rgba(0, 0, 0, 0.3) 90%,
        rgba(0, 0, 0, 0.15) 95%,
        rgba(0, 0, 0, 0) 100%
    );
}

/*  
 *  How to use:
 *  @extend %backgroundGradientLight;
 */

 %backgroundGradientLight {
    background: colours.$white;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.8) 70%,
        rgba(255, 255, 255, 0.7) 75%,
        rgba(255, 255, 255, 0.6) 80%,
        rgba(255, 255, 255, 0.5) 85%,
        rgba(255, 255, 255, 0.3) 90%,
        rgba(255, 255, 255, 0.15) 95%,
        rgba(255, 255, 255, 0) 100%
    );
}